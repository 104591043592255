import { InertiaFormProps } from '@inertiajs/inertia-react';
import { createContext } from 'react';
import { AnyObjectSchema } from 'yup';

export const FormikContext = createContext<{
  validationSchema: AnyObjectSchema;
  inertia?: InertiaFormProps;
}>({
  validationSchema: {} as AnyObjectSchema,
  inertia: undefined,
});

FormikContext.displayName = 'CustomFormikContext';

export default FormikContext;
